<template>
  <div class="m-8">
    <v-row align="center" justify="center" class="button-print">
      <v-btn color="primary" @click="print"> IN PHIẾU ĐIỀU TRỊ </v-btn>
    </v-row>
    <v-row class="mt-0">
      <v-col cols="8">
        <p class="text-medium mb-0">PHÒNG KHÁM CHUYÊN KHOA Y HỌC CỔ TRUYỀN BẢO ĐẠI ĐƯỜNG</p>
        <p class="text-small mb-0">Cố vấn chuyên môn: <span>PGS.TS.BS. Lê Lương Đống</span></p>
        <p class="text-small mb-0">Phụ trách chuyên môn: <span>BS. Trần Thị Phương Hoa</span></p>
        <p class="text-small mb-0">GPHĐ: <span>525/HNO-GPHĐ</span></p>
        <p class="text-small mb-0">Địa chỉ: <span>146 ngõ 136 Tây Sơn (148 ngõ 91 Trần Quang Diệu) Đống Đa – Hà Nội</span></p>
        <p class="text-small mb-0">ĐT: <span>084.22.11.348 - 084.23.11.348</span></p>
      </v-col> 
      <v-col>
        <v-row>
          <img src="../assets/logo/logo.png" alt="logo" class="w-15 h-12 " />
          <p class="brand mt-1">BẢO ĐẠI ĐƯỜNG</p>
        </v-row>
        <v-row>
            <img class="qr-code ml-20" src='https://res.cloudinary.com/dl2pj1j7w/image/upload/v1680756441/quanlyphongkham/baodaiduong/patient-prescription/4/oorv0yvnagicmpuwygux.png'/>
         
        </v-row>
      </v-col> 
    </v-row>
    <v-row align="center" justify="center">
      <div class="text-h5 title">PHIẾU ĐIỀU TRỊ</div>
    </v-row>
    <v-row class="mt-4 mb-0 py-0">
      <v-col cols="8" class="py-0">
        <p class="text-h7">Bệnh nhân:</p>
      </v-col>
      <v-col cols="2" class="py-0">
        <p class="text-h7">Tuổi:</p>
      </v-col>
      <v-col cols="2" class="py-0">
        <p class="text-h7">Giới tính: <span>xxxx</span></p>
      </v-col>
    </v-row>
    <p class="text-h7">Địa chỉ: </p>
    <p class="text-h7">Điện thoại: </p>
    <p class="text-h7">Triệu chứng:</p>
    <p class="text-h7">Chẩn đoán:</p>
    <p class="text-h7">Điều trị:</p>
    <div class="row mt-0">
      <div style="display: inline-block; padding-left: 10px;" >
        <p class="mt-2">-Thuốc: Liệu trình</p>
      </div>
      <div style="display: inline-block; padding-left: 10px; padding-right: 10px; width: 5%;">
          <v-text-field hide-details single-line class="shrink" dense variant="underlined"></v-text-field>
      </div>
      <div class="column3" style="display: inline-block;">
        <p class="mt-2">tuần bao gồm:</p>
      </div>
    </div>
    <v-row class="my-3">
      <v-data-table
        class="table-data custom-border-table table-no-stripe"
        no-data-text="Không có dữ liệu"
        :headers="headers"
        :items="items"
        disable-pagination
        hide-default-footer
      >
        <template v-slot:[`item.name`]="{ item }">
          <span>{{ item.name }}</span><br>
          <div class="flex mb-2">
            <p class="mb-0" style="min-width: 90px">Cách dùng: </p>
            <span class="break-words print-show">{{ item.modelGuide }}</span>
            <v-textarea
              v-model="item.modelGuide"
              hide-details
              auto-grow
              rows="1"
              class="mt-0 pt-0 custom-prepend items-center print-hidden"
            />
          </div>
        </template>
      </v-data-table>
    </v-row>
    
    <div class="flex">
      <p class="text-h7 mb-0" style="min-width: 155px">- Thủ thuật (Nếu có):</p>
      <span class="break-words print-show">{{ modelTips }}</span>
      <v-textarea
        v-model="modelTips"
        hide-details
        auto-grow
        rows="1"
        class="mt-0 pt-0 custom-prepend print-hidden"
      />
    </div>
    <div class="flex mt-2">
      <p class="mb-0" style="min-width: 84px">Chế độ ăn: </p>
      <span class="break-words print-show">{{ modelPlan }}</span>
      <v-textarea
        v-model="modelPlan"
        hide-details
        auto-grow
        rows="1"
        class="mt-0 pt-0 custom-prepend print-hidden"
      />
    </div>
    <div class="flex mt-2">
      <p class="mb-0" style="min-width: 210px">Lời dặn, kết luận của bác sỹ:  </p>
      <span class="break-words print-show">{{ modelAdviceFromDoctor }}</span>
      <v-textarea
        v-model="modelAdviceFromDoctor"
        height="28"
        hide-details
        auto-grow
        rows="1"
        class="mt-0 pt-0 custom-prepend print-hidden"
      />
    </div>
    <v-row class="mt-2">
      <v-col cols="8">
      </v-col> 
      <v-col align="center" justify="center">
        <span class="date">Ngày xx tháng xx năm xxxx</span>
        <p align="center" justify="center">Bác Sỹ</p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { required } from "~/utils/validation";
import { mapState } from "vuex";
import { EMPTY } from "~/utils/constants";

export default {
  name: "PhieuDieuTri",
  data() {
    return {
      isInputPhone: false,
      phoneModel: null,
      valid: true,
      modelTips: "",
      modelPlan: "",
      modelAdviceFromDoctor: "",
      rules: {
        required,
      },
      headers: [
        { text: "STT", value: "id", sortable: false, align: "left"  },
        { text: "TÊN THUỐC", value: "name", sortable: false, align: "left" },
        { text: "SỐ LƯỢNG", value: "quantity", sortable: false, align: "left" },
        { text: "THÀNH TIỀN", value: "pricePerPiece", sortable: false, align: "left" },
      ],
      items: [
        { id: 1, name: "Thuốc 1", quantity: 10, pricePerPiece: "10.000vnđ", modelGuide: "" },
        { id: 2, name: "Thuốc 2", quantity: 10, pricePerPiece: "10.000vnđ", modelGuide: "" }
      ],
    };
  },
  computed: {
    ...mapState({
      name: (state) => state.prescriptionForPatient.name || EMPTY,
      address: (state) => state.prescriptionForPatient.address || EMPTY,
      phone: (state) => state.prescriptionForPatient.phone || EMPTY,
      doctorAt: (state) => state.prescriptionForPatient.doctorAt || EMPTY,
      reExamination: (state) => state.prescriptionForPatient.reExamination || EMPTY,
      prescription: (state) =>
        state.prescriptionForPatient.prescription.map((item) => ({
          ...item,
          pricePerPiece: "1.000 vnđ",
        })) || [],
      price: (state) => state.prescriptionForPatient.price || EMPTY,
      howToUse: (state) => state.prescriptionForPatient.howToUse || EMPTY,
      reason: (state) => state.prescriptionForPatient.reason || EMPTY,
    }),
  },
  methods: {
    print() {
      window.print();
    },
    validate() {
      this.$refs.form.validate();
    },
  },
};
</script>

<style type="sass" media="print" scoped>
@page {
  size: auto;
  margin: 0;
}

.print-show {
  display: none;
}

.print-hidden {
  display: block;
}

@media print {
  .print-show {
    display: block;
  }

  .print-hidden {
    display: none;
  }

  .button-print {
    display: none;
  }
}

p {
  margin-bottom: 4px;
  color: black !important;
  font-family: 'SanFrancisco-Bold';
}

span {
  color: black !important;
  font-family: 'SanFrancisco' !important;
}

.text-small {
  font-size: 14px;
}

.text-medium {
  font-size: 16px;
}

.text-bold {
  font-weight: bold;
}

.title {
  color: black !important;
  font-family: 'SanFrancisco-Bold' !important;
  font-size: 25px !important;
}

.brand {
  color: black !important;
  font-family: 'Mabella' !important;
  font-size: 30px !important;
}

.table-data {
  width: 100% !important;
  color: black !important;
}

.qr-code {
   width: 60px !important;
   height: 60px !important;
}
</style>
